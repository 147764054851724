import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
let List = class List extends Vue {
};
List = __decorate([
    Component({
        components: {
            OcorrenciaComponent,
        },
    })
], List);
export default List;
